/* Imported Css Url */

@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Basic Css */

:root {
  /*Basics*/
  --site-font-family: 'Rubik', sans-serif;
  --site-font-size: 16px;
  --site-line-height: 24px;

  /*Typography*/
  --h1-font-size: 50px;
  --h2-font-size: 35px;
  --h3-font-size: 30px;
  --h4-font-size: 25px;
  --h5-font-size: 20px;
  --h6-font-size: 18px;

  /*Wrapper*/
  --wrapper: 1170px;

  /*Transition*/
  --transition: all 400ms ease;

  /*FontSizes*/
  --font-size-10: 10px;
  --font-size-11: 11px;
  --font-size-12: 12px;
  --font-size-13: 13px;
  --font-size-14: 14px;
  --font-size-15: 15px;
  --font-size-16: 16px;
  --font-size-17: 17px;
  --font-size-18: 18px;
  --font-size-19: 19px;
  --font-size-20: 20px;
  --font-size-21: 21px;
  --font-size-22: 22px;
  --font-size-23: 23px;
  --font-size-24: 24px;
  --font-size-25: 25px;
  --font-size-26: 26px;
  --font-size-27: 27px;
  --font-size-28: 28px;
  --font-size-29: 29px;
  --font-size-30: 30px;
  --font-size-40: 40px;
  --font-size-41: 41px;
  --font-size-42: 42px;
  --font-size-43: 43px;
  --font-size-44: 44px;
  --font-size-45: 45px;

  /*FontWeights*/
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;
  --font-weight-900: 900;

  /*Colours*/
  --primary-colour: #ff7a00;
  --primary-text-colour: #191720;
  --primary-black-colour: #000;
  --primary-white-colour: #fff;
  --primary-error-colour: #fc3737;
  --primary-success-colour: #33ab1f;
  --primary-gray-colour: #8d91a1;
  --secondary-text-colour: #535558;
  --secondary-text-colour-red: #b00202;
  --link-colour: #c3c3c3;
  --link-hover-colour: #000000;
  --border-colour: #c3c3c3;
}

body {
  font-family: var(--site-font-family);
  font-size: var(--site-font-size);
  color: var(--secondary-text-colour);
  line-height: var(--site-line-height);
  margin: 0;
  padding: 0px 0 0 0;
  background: var(--site-bgcolor);
  overflow-x: hidden;
  text-align: center;
}

h1 {
  font-size: var(--h1-font-size);
  font-family: var(--site-font-family);
  color: var(--primary-colour);
  line-height: normal;
  text-transform: uppercase;
}
h2 {
  font-size: var(--h2-font-size);
  font-family: var(--site-font-family);
  color: var(--primary-text-colour);
  line-height: normal;
}
h3 {
  font-size: var(--h3-font-size);
  font-family: var(--site-font-family);
  color: var(--primary-text-colour);
  line-height: normal;
}
h4 {
  font-size: var(--h4-font-size);
  font-family: var(--site-font-family);
  color: var(--primary-text-colour);
  line-height: normal;
}
h5 {
  font-size: var(--h5-font-size);
  font-family: var(--site-font-family);
  color: var(--primary-text-colour);
  line-height: normal;
}
h6 {
  font-size: var(--h6-font-size);
  font-family: var(--site-font-family);
  color: var(--primary-text-colour);
  line-height: normal;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none;
  outline: 0;
}
.wrapper {
  margin: 0 auto !important;
  width: var(--wrapper);
  max-width: 100%;
  float: none !important;
  color: white;
}

a {
  text-decoration: none;
  outline: none;
  display: inline-block;
  color: var(--link-colour);
  transition: var(--transition);
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -o-transition: var(--transition);
}

a:hover {
  color: var(--link-hover-colour);
}

p {
  float: left;
  width: 100%;
  margin: 10px 0;
}
.anchor-link {
  color: #000 !important;
}
.anchor-link {
  margin-right: 18px;
  cursor: pointer;
}

.anchor-link a .fa {
  margin-right: 5px;
}
h1 + p,
h2 + p,
h3 + p,
h4 + p,
h5 + p,
h6 + p {
  margin-top: 0;
}
.left-auto {
  float: left;
  width: auto;
  margin: 0;
}
.right-auto {
  float: right;
  width: auto;
  margin: 0;
}
.full-width {
  float: left;
  width: 100%;
}
.child-triggerm,
.sub-menu {
  display: none;
}
.bgimg-main {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}
.bg-img {
  display: none;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.relative {
  position: relative;
}
ul {
  float: left;
  width: 100%;
  margin: 0;
  padding: 0;
}

.landing-page {
  background: url('../../public/images/left-auth-image.png');
  color: var(--primary-white-colour);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body.innerPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.header-section {
  padding: 40px 0 0 0;
}
.banner-section {
  padding: 0 0 60px 0;
}
.banner-section h1 {
  float: left;
  width: 100%;
  margin: 28px 0 47px;
  color: var(--primary-white-colour);
}
.banner-section h1 span {
  float: left;
  width: 100%;
  color: var(--primary-colour);
  line-height: 10px;
}
.app-cta {
  display: flex;
  justify-content: center;
}
.app-cta a img {
  border-radius: 10px;
}
.app-cta a {
  margin: 0 7px;
  align-items: center;
}
.app-cta a:hover {
  transform: translateY(-3px);
}
.devices-img {
  margin: 65px 0 43px;
}
.devices-img img {
  max-width: 100%;
}

.footer-section {
  border-top: 1px solid var(--primary-gray-colour);
  padding: 40px 0;
  margin-top: auto;
  font-size: var(--font-size-14);
  color: var(--primary-gray-colour);
}
.footer-section .wrapper {
  display: flex;
  justify-content: space-between;
}
.footer-section a {
  margin-left: 2px;
  color: var(--primary-gray-colour);
}
.footer-section a span {
  margin-left: 7px;
}
.footer-section a:hover {
  text-decoration: underline;
}

.innerPage .header-section {
  border-bottom: 1px solid var(--primary-gray-colour);
  padding: 30px 0;
  line-height: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: left;
  background: #111111;
}
.innerPage .banner-section {
  padding-top: 92px;
  text-align: justify;
}
.innerPage .banner-section h1 {
  margin: 40px 0 22px;
}
.innerPage .footer-section,
.innerPage .footer-section a {
  color: var(--primary-white-colour);
}
.innerPage h1 {
  font-size: 35px;
}

.aboutUsContent {
  display: flex;
}
.aboutUsContent > div {
  width: 60%;
}
.aboutUsContent > div:last-child {
  text-align: center;
  width: 40%;
}
.aboutUsContent > div img {
  max-width: 100%;
}

.banner-section h5 {
  float: left;
  width: 100%;
  color: var(--primary-white-colour);
  margin: 0 0 15px 0;
}
.banner-section p + h5 {
  margin-top: 15px;
}
.banner-section.innerPage p a,
.banner-section.innerPage ul li a {
  text-decoration: underline;
  color: var(--primary-white-colour);
}
.banner-section.innerPage ol,
.banner-section.innerPage ol li,
.banner-section.innerPage ul,
.banner-section.innerPage ul li {
  float: left;
  width: 100%;
  margin: 0;
  list-style-type: decimal;
}
.banner-section.innerPage ol li,
.banner-section.innerPage ul li {
  margin: 0 0 15px 0;
}
.banner-section.innerPage ol li:last-child {
  margin-bottom: 0;
}
.app-cta.aboutCta {
  margin: 10px 0 35px 0;
}
/* .app-cta.aboutCta a { margin:0 15px 0 0 } */
.termsPage p + h5 {
  padding-top: 20px;
}
.banner-section.innerPage ul,
.banner-section.innerPage ul li {
  list-style-type: disc;
}
.banner-section.innerPage p + ul {
  padding: 20px 0 0 20px;
}
.subTitle {
  margin: 20px 0 0 0;
}
.leftContent {
  padding-left: 50px;
  padding-bottom: 25px;
}
.leftContent p {
  margin-bottom: 0;
}
.banner-section.lp h1 span {
  font-size: var(--font-size-40);
}
.banner-section.innerPage ul li p {
  margin-top: 0;
}
.banner-section.innerPage ol li p {
  margin: 10px 0 0 0;
}

.categoryTable {
  margin: 20px 0 15px;
}
.categoryTableTop,
.categoryTableRow {
  display: flex;
  text-align: left;
}
.categoryTableTop {
  border-top: 1px solid #fff;
}
.categoryTableTop > div,
.categoryTableRow > div {
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  padding: 12px 15px;
  line-height: 22px;
}
.categoryTableTop > div:first-child,
.categoryTableRow > div:first-child {
  border-left: 1px solid #fff;
}
.categoryTableTop > div:first-child,
.categoryTableRow > div:first-child {
  float: left;
  width: 30%;
}
.categoryTableTop > div:nth-child(2),
.categoryTableRow > div:nth-child(2) {
  float: left;
  width: 58%;
}
.categoryTableTop > div:nth-child(3),
.categoryTableRow > div:nth-child(3) {
  float: left;
  width: 12%;
}

.ctaStrip {
  background: var(--primary-colour);
  text-align: center;
  padding: 20px 0 0 0;
  margin: 20px 0 40px 0;
}
.ctaStrip h5 {
  margin-bottom: 0;
}
.ctaStrip .aboutCta {
  margin-top: 20px;
}

/* Media Screen */

@media (max-width: 1240px) {
  .wrapper {
    width: 100%;
    padding: 0 25px;
  }
}

@media (max-width: 992px) {
  :root {
    --h1-font-size: 40px;
  }
  .aboutUsContent > div:last-child {
    padding-left: 30px;
  }
  .banner-section.lp h1 span {
    font-size: var(--font-size-30);
  }
}

@media (min-width: 768px) {
  .banner-section.lp h1,
  .banner-section.lp h1 span {
    line-height: 59px;
  }
  .banner-section.lp h1 {
    margin: 53px 0 22px;
  }
  .banner-section.innerPage p {
    margin-top: 20px;
  }
  .banner-section.innerPage h5 + p {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  :root {
    --site-font-size: 14px;
    --site-line-height: 24px;
    --section-padding: 50px 0px;
    --h1-font-size: 30px;
  }
  .banner-section.lp h1 span {
    font-size: var(--font-size-24);
    margin-top: 5px;
  }
  .aboutUsContent > div:last-child {
    padding-left: 0px;
    text-align: center;
    width: 100%;
    margin: 5px 0 15px;
  }
  .banner-section h1 {
    line-height: 40px;
    margin: 40px 0 20px;
  }
  .banner-section h1 span {
    line-height: 38px;
  }
  .footer-section .wrapper {
    flex-direction: column-reverse;
  }
  .footer-section .wrapper > div {
    margin: 7px 0;
    line-height: 20px;
  }
  .innerPage h1 {
    font-size: 28px;
  }
  .devices-img {
    margin: 35px 0 40px;
  }
  .app-cta a img {
    max-width: 150px;
  }
  .banner-section {
    padding: 0 0 40px 0;
  }
  .footer-section {
    padding: 25px 0 25px;
  }
  .aboutUsContent {
    flex-direction: column-reverse;
  }
  .aboutUsContent > div {
    width: 100%;
  }
  .innerPage .header-section,
  .innerPage .banner-section h1 {
    text-align: center;
  }
  .innerPage .banner-section h1 {
    margin: 40px 0 30px;
  }
  .banner-section.lp h1 + p br {
    display: none;
  }
  .banner-section.lp h1 + p {
    text-align: justify;
  }
  .footer-section a {
    margin: 3px 0 3px 0;
  }
  .banner-section.innerPage ol {
    padding-left: 25px;
  }
  .categoryTable {
    overflow: auto;
  }
  .categoryTable > div {
    width: 800px;
  }
}
