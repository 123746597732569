@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

/* Basic Css */
:root {
  --site-font-family: 'Ubuntu', sans-serif;
  --site-font-size: 14px;
  --site-text-color: #262626;
  --site-line-height: 26px;
  --h1-font-size: 40px;
  --h2-font-size: 36px;
  --h3-font-size: 32px;
  --h4-font-size: 28px;
  --h5-font-size: 24px;
  --h6-font-size: 20px;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none;
  outline: 0;
}

body {
  font-family: var(--site-font-family) !important;
  font-size: var(--site-font-size) !important;
  color: var(--site-text-color) !important;
  line-height: var(--site-line-height) !important;
  margin: 0;
  padding: 0px 0 0 0 !important;
}

.wrapper {
  margin: 0 auto !important;
  width: 1300px;
  max-width: 100%;
  float: none !important;
  padding: 0 100px;
}
a {
  display: inline-block;
  transition: all 400ms ease;
  text-decoration: none;
}
.fullWindow {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.full-width {
  float: left;
  width: 100%;
}
.headerTop {
  background: #f1f5f8;
  color: #000;
  padding: 7px 0;
}
.headerTop a {
  margin-right: 18px;
  cursor: pointer;
}
.headerTop a:hover {
  color: #8ec504 !important;
}
.headerTop a .fa {
  margin-right: 5px;
}

.headerBottom {
  padding: 25px 0;
}
.banner .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.banner {
  padding-bottom: 30px;
}
.banner h1 {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 25px;
}
.bannerCTA a {
  margin-bottom: 25px;
}
.bannerCTA a img {
  max-width: 100%;
}
.bannerRight img {
  animation: smoothbounceball 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  transition: all 1000ms ease;
  max-width: 100%;
}
.bannerLeft,
.bannerRight {
  width: 50%;
}
.footer {
  background: #8ec504;
  color: #fff;
  margin-top: auto;
  padding: 14px 0;
}
.footer .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footerSocial {
  display: flex;
}
.footerSocial a {
  width: 18px;
  height: 18px;
  font-size: 11px;
  margin-left: 7px;
  border-radius: 100%;
  background: #fff;
  color: #8ec504;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footerSocial a:hover {
  transform: translateY(-3px);
}
.bannerCTA a:hover {
  transform: translateY(-5px);
}

@keyframes smoothbounceball {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, 20px, 0);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .bannerLeft {
    padding-right: 50px;
  }
}
@media (min-width: 30px) and (max-width: 767px) {
  .wrapper {
    width: 100%;
    padding: 0 25px;
  }
  .banner .wrapper,
  .footer .wrapper {
    flex-direction: column-reverse;
  }
  .bannerLeft,
  .bannerRight {
    width: 100%;
  }
  .bannerRight {
    margin-bottom: 30px;
  }
  .bannerCTA a {
    margin-bottom: 10px;
  }
  .banner h1 {
    padding-bottom: 10px;
    font-size: 20px;
  }
  .footer {
    text-align: center;
    line-height: normal;
  }
  .footerSocial a {
    margin: 0 3px 10px;
  }
}
